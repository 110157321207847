export const formDataHelper = (
  rawData,
  categories,
  personioId,
  personioTerms,
  personioUploadsDescription,
  recaptchaSitekey,
  t
) => {
  const { formType, formToggle, linkTo, pardot, reference } = rawData;
  const newData = {
    formToggle,
    linkTo,
    submitLabel: t('form.submitLabel'),
    submitUrl: null,
    formId: null,
    initialFormData: []
  };

  if (formType === 'internal' && reference[0] && reference[0].additionalFormFields) {
    // format craft form data
    newData.submitUrl = '/api/ccg/forms/submit';
    newData.formId = reference[0].id;

    reference[0].additionalFormFields.forEach(field => {
      if (field.__typename) {
        switch (field.__typename) {
          case 'additionalFormFields_text_BlockType':
            newData.initialFormData.push({
              type: 'text',
              id: field.id,
              label: field.label,
              required: field.required
            });
            break;
          case 'additionalFormFields_email_BlockType':
            newData.initialFormData.push({
              type: 'email',
              id: field.id,
              label: field.label,
              required: field.required
            });
            break;
          case 'additionalFormFields_select_BlockType':
            newData.initialFormData.push({
              type: 'select',
              id: field.id,
              label: field.label,
              required: field.required,
              options: field.options
            });
            break;
          case 'additionalFormFields_radio_BlockType':
            newData.initialFormData.push({
              type: 'radio',
              id: field.id,
              label: field.label,
              required: field.required,
              options: field.options.map(option => ({ label: option.label, value: option.label })),
              value: field.options[0].value
            });
            break;
          case 'additionalFormFields_checkbox_BlockType':
            newData.initialFormData.push({
              type: 'checkbox',
              id: field.id,
              label: field.label,
              required: field.required,
              options: field.options
            });
            break;
          case 'additionalFormFields_upload_BlockType':
            newData.initialFormData.push({
              type: 'upload',
              id: field.id,
              label: field.label,
              required: field.required
            });
            break;
          default:
            newData.initialFormData.push(field);
        }
      }
    });

    newData.initialFormData.push({ type: 'honeypot', id: 'a_password' });
  }

  if (formType === 'pardot' && pardot && pardot.id) {
    // format pardot form data
    newData.submitUrl = '/api/ccg/pardot/forms/submit';
    newData.formId = pardot.id;
    pardot.fields.forEach((field, index) => {
      if (field.type) {
        switch (field.type) {
          case 'text':
            newData.initialFormData.push({
              type: 'text',
              id: field.name,
              label: field.label,
              required: field.required
            });
            break;
          case 'email':
            newData.initialFormData.push({
              type: 'email',
              id: field.name,
              label: field.label,
              required: field.required
            });
            break;
          case 'select':
            newData.initialFormData.push({
              type: 'select',
              id: field.name,
              label: field.label,
              required: field.required,
              options: field.options
            });
            break;
          case 'paragraph':
            newData.initialFormData.push({
              type: 'paragraph',
              content: field.value
            });
            break;
          case 'checkbox':
            newData.initialFormData.push({
              type: 'checkbox',
              id: field.name,
              label: field.label,
              required: field.required,
              value: field.value
            });
            break;
          case 'radio':
            newData.initialFormData.push({
              type: 'radio',
              id: field.name || field.options[0].name || `radio-${index}`,
              label: field.label,
              value: field.options[0].value,
              required: field.required,
              options: field.options
            });
            break;
          default:
            newData.initialFormData.push(field);
        }
      }
    });

    newData.initialFormData.push({ type: 'honeypot', id: 'a_password' });

    newData.initialFormData.push({
      type: 'recaptcha',
      id: `recaptcha-${pardot.id}`,
      label: 'Recaptcha',
      value: '',
      recaptchaSitekey
    });
  }

  if (
    (formType === 'personio' ||
      formType === 'personioQuestions' ||
      formType === 'personioQuestionsIntern' ||
      formType === 'personioShort') &&
    personioId &&
    personioTerms
  ) {
    // format personio form data
    newData.submitUrl = '/api/ccg/forms/personio/submit';
    newData.formId = personioId;

    if (formType === 'personioShort') {
      const seniroityOptions = categories
        .filter(({ groupHandle }) => groupHandle === 'seniority')
        .map(e => ({ id: e.id, value: e.id, label: e.title }));

      const departmentsOptions = categories
        .filter(({ groupHandle }) => groupHandle === 'departments')
        .map(e => ({ id: e.id, value: e.id, label: e.title }));

      newData.initialFormData = [
        {
          type: 'text',
          id: 'first_name',
          label: t('form.firstName'),
          required: true
        },
        {
          type: 'text',
          id: 'last_name',
          label: t('form.lastName'),
          required: true
        },
        {
          type: 'email',
          id: 'email',
          label: 'Email',
          required: true
        },
        {
          type: 'text',
          id: 'phone',
          label: t('form.phone'),
          required: false
        },
        {
          type: 'text',
          id: 'custom_1154194',
          label: t('form.socialJobProfile'),
          required: false
        },
        {
          type: 'select',
          id: 'seniority',
          label: t('form.careerLevel'),
          placeholder: t('form.careerLevel'),
          required: true,
          options: seniroityOptions
        },
        {
          type: 'select',
          id: 'departments',
          label: t('form.fieldOfActivity'),
          placeholder: t('form.fieldOfActivity'),
          required: true,
          options: departmentsOptions
        },
        {
          type: 'upload',
          id: 'documents[]',
          label: t('form.personioUploads'),
          description: personioUploadsDescription || t('form.personioUploadsDescription'),
          multiple: true,
          required: false
        },
        {
          type: 'paragraph',
          id: 'terms',
          content: personioTerms
        },
        {
          type: 'checkbox',
          id: `termsAccepted-${personioId}`,
          label: t('form.personioTermsAccept'),
          required: true
        },
        { type: 'honeypot', id: 'a_password' },
        {
          type: 'recaptcha',
          id: `recaptcha-${personioId}`,
          label: 'Recaptcha',
          value: '',
          recaptchaSitekey
        }
      ];
    }

    if (
      formType === 'personio' ||
      formType === 'personioQuestions' ||
      formType === 'personioQuestionsIntern'
    ) {
      newData.initialFormData = [
        {
          type: 'text',
          id: 'first_name',
          label: t('form.firstName'),
          required: true
        },
        {
          type: 'text',
          id: 'last_name',
          label: t('form.lastName'),
          required: true
        },
        {
          type: 'text',
          id: 'custom_675',
          label: t('form.streetAndNumber'),
          required: true
        },
        {
          type: 'text',
          id: 'custom_677',
          label: t('form.zip'),
          required: true
        },
        {
          type: 'text',
          id: 'location',
          label: t('form.location'),
          required: true
        },
        {
          type: 'email',
          id: 'email',
          label: 'Email',
          required: true
        },
        {
          type: 'text',
          id: 'phone',
          label: t('form.phone'),
          required: true
        },
        {
          type: 'text',
          id: 'available_from',
          label: t('form.availableFrom'),
          required: true
        },
        {
          type: 'text',
          id: 'salary_expectations',
          label: t('form.salaryExpectations'),
          required: true
        },
        {
          type: 'text',
          id: 'custom_697',
          label: t('form.xingProfile'),
          required: false
        },
        {
          type: 'text',
          id: 'custom_699',
          label: t('form.linkedInProfile'),
          required: false
        },
        {
          type: 'upload',
          id: 'documents[]',
          label: t('form.personioUploads'),
          description: personioUploadsDescription || t('form.personioUploadsDescription'),
          multiple: true,
          required: true
        },
        {
          type: 'paragraph',
          id: 'terms',
          content: personioTerms
        },
        {
          type: 'checkbox',
          id: `termsAccepted-${personioId}`,
          label: t('form.personioTermsAccept'),
          required: true
        },
        { type: 'honeypot', id: 'a_password' },
        {
          type: 'recaptcha',
          id: `recaptcha-${personioId}`,
          label: 'Recaptcha',
          value: '',
          recaptchaSitekey
        }
      ];

      if (formType === 'personioQuestions') {
        newData.initialFormData.unshift(
          {
            type: 'textarea',
            id: 'custom_650718',
            label: t('form.whyThisJob'),
            required: true
          },
          {
            type: 'textarea',
            id: 'custom_650719',
            label: t('form.whyYou'),
            required: true
          }
        );
      }

      if (formType === 'personioQuestionsIntern') {
        newData.initialFormData.unshift(
          {
            type: 'input',
            id: 'custom_attribute_2420299',
            label: t('form.whatInternshipType'),
            required: true
          },
          {
            type: 'input',
            id: 'custom_attribute_1154194',
            label: t('form.internshipLength'),
            required: true
          }
        );
      }
    }
  }

  return newData;
};
