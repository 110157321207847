
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NewsTeaser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"news_news_Entry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tags"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TeaserImage"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"subline"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"featured"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"colorTheme"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":227}};
    doc.loc.source = {"body":"#import '../images/teaserImage.graphql'\n\nfragment NewsTeaser on news_news_Entry {\n  id\n  title\n  slug\n  uri\n  postDate\n  tags {\n    title\n    id\n    slug\n  }\n  image {\n    ...TeaserImage\n  }\n  subline\n  featured\n  colorTheme\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('../images/teaserImage.graphql').definitions));


      module.exports = doc;
    
