/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../i18n';

import TeaserListProvider from './context/TeaserListProvider';
import TeaserList from './subcomponents/TeaserList';

const AutomaticTeaserContainer = ({
  id,
  headline,
  subline,
  linkTo,
  limit,
  customLinkText,
  showLoadMore,
  showFilter,
  featured,
  type,
  sections,
  relatedToAll,
  additionalQueryArgs
}) => {
  return (
    <TeaserListProvider
      id={id}
      limit={limit}
      relatedToAll={relatedToAll}
      showFilter={showFilter}
      showLoadMore={showLoadMore}
      featured={featured}
      customLinkText={customLinkText}
      sections={sections}
      type={type}
      additionalQueryArgs={additionalQueryArgs}
    >
      <TeaserList headline={headline} subline={subline} linkTo={linkTo} />
    </TeaserListProvider>
  );
};

AutomaticTeaserContainer.propTypes = {
  id: PropTypes.string.isRequired,
  headline: PropTypes.string,
  subline: PropTypes.string,
  linkTo: PropTypes.shape(),
  /**
   * Define which type to query
   */
  type: PropTypes.oneOf(['categories', 'entries']).isRequired,
  /**
   * Set limit of entries
   */
  limit: PropTypes.number,
  /**
   * Optionally override the cta text for the teaser links
   */
  customLinkText: PropTypes.number,
  /**
   * Define if should show load more button
   */
  showLoadMore: PropTypes.bool,
  /**
   * Define if featured entries should show up first
   */
  featured: PropTypes.bool,
  /**
   * Define if should show filter.
   * Currently only works if sections is ["cases"] or ["jobs"] and
   * no additionalQueryArgs are given
   */
  showFilter: PropTypes.bool,
  /**
   * Get entries based on relatedToAll ids
   */
  relatedToAll: PropTypes.arrayOf(PropTypes.string),
  /**
   * Define which sections should be queried.
   * @example { type: ["entries"], sections: ["cases", "jobs"] }
   * @example { type: ["categories"], sections: ["solutions", "industries"] }
   */
  sections: PropTypes.arrayOf(PropTypes.string),
  /**
   * Add additional query arguments to query
   * @example { companies: ["2154"], locations: ["2419", "531"] }
   */
  additionalQueryArgs: PropTypes.shape()
};

AutomaticTeaserContainer.defaultProps = {
  limit: null,
  headline: null,
  subline: null,
  linkTo: null,
  customLinkText: null,
  showLoadMore: false,
  showFilter: false,
  featured: false,
  sections: undefined,
  relatedToAll: undefined,
  additionalQueryArgs: {}
};

export default withTranslation('common')(AutomaticTeaserContainer);
