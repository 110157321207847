/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';

import { DataContext } from '@ccg/core';

import EntriesQuery from '../../../../graphql/queries/teaserContainer/entries.graphql';
import CategoriesQuery from '../../../../graphql/queries/teaserContainer/categories.graphql';

const queries = {
  entries: EntriesQuery,
  categories: CategoriesQuery
};

export const TeaserListContext = React.createContext();

const TeaserListProvider = ({
  id,
  children,
  limit,
  customLinkText,
  sections,
  type,
  showLoadMore,
  showFilter,
  featured,
  relatedToAll,
  additionalQueryArgs
}) => {
  const router = useRouter();

  const initialFilters = router.query.filters ? JSON.parse(router.query.filters) : undefined;

  const [selectedFilter, setSelectedFilter] = useState(
    initialFilters && initialFilters[id] ? initialFilters[id] : []
  );
  const [loadMore, setLoadMore] = useState(false);
  const [hideLoadMore, setHideLoadMore] = useState(false);

  const { site } = useContext(DataContext);

  /**
   * Check if teaser container is filterable
   * only true if section is equal to ["cases"] or ["jobs"] and no
   * predefined filters are set
   */
  const isFilterable =
    sections &&
    sections.length === 1 &&
    (sections[0] === 'jobs' || sections[0]) &&
    (!relatedToAll || relatedToAll.length === 0);

  const getRelatedToAll = () => {
    if (relatedToAll && relatedToAll.length > 0) return { relatedTo: ['and', ...relatedToAll] };
    return {};
  };

  const orderBy = featured ? 'featured DESC, postDate DESC' : 'postDate DESC';

  const { data, loading, error, fetchMore, refetch } = useQuery(queries[type], {
    variables: {
      site,
      limit,
      sections,
      orderBy,
      ...getRelatedToAll(),
      ...additionalQueryArgs
    }
  });

  /**
   * Buils filter object based on group
   * e.g. { locations: [124, 521], companies: [213, 531] }
   */
  const buildFilterObject = newFilter => {
    const defaultFilter = {
      news: null,
      clients: null,
      companies: null,
      locations: null,
      industries: null,
      solutions: null,
      tasks: null,
      employmentType: null,
      seniority: null,
      events: null
    };

    if (!newFilter) return defaultFilter;

    return newFilter.reduce((map, obj) => {
      if (Array.isArray(map[obj.group])) {
        map[obj.group].push(parseInt(obj.id, 10));
      } else {
        map[obj.group] = [parseInt(obj.id, 10)];
      }

      return map;
    }, defaultFilter);
  };

  /**
   * Update query if filter changes
   */
  useEffect(() => {
    if (showFilter) {
      const filter = buildFilterObject(selectedFilter);

      refetch({
        site,
        limit,
        sections,
        ...filter
      });
    }
  }, [selectedFilter]);

  const loadMoreTeasers = async () => {
    if (!loading) {
      setLoadMore(true);

      try {
        await fetchMore({
          variables: {
            offset: data.entries.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            setLoadMore(false);

            if (data.entries.length + limit >= data.total) {
              setHideLoadMore(true);
            }
            return { ...prev, entries: [...prev.entries, ...fetchMoreResult.entries] };
          }
        });
      } catch (e) {
        console.error('Something went wrong while loading more teasers');
      }
    }
  };

  /**
   * Update selectedFilter array with newFilter array
   * and set undefined if newFilter length is 0
   */
  const updateFilter = newFilter => {
    const filters = {
      ...(router.query.filters ? JSON.parse(router.query.filters) : {}),
      [id]: newFilter
    };

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, filters: JSON.stringify(filters) }
      },
      `${window.location.pathname}?filters=${encodeURIComponent(JSON.stringify(filters))}`,
      { shallow: true }
    );

    return setSelectedFilter(newFilter.length === 0 ? undefined : newFilter);
  };

  return (
    <TeaserListContext.Provider
      value={{
        loadMoreTeasers,
        data,
        error,
        limit,
        customLinkText,
        type,
        sections,
        updateFilter,
        selectedFilter,
        loading: loading || loadMore,
        showLoadMore: showLoadMore && !hideLoadMore,
        showFilter: showFilter && isFilterable,
        featured
      }}
    >
      {children}
    </TeaserListContext.Provider>
  );
};

TeaserListProvider.propTypes = {
  id: PropTypes.string.isRequired,
  showLoadMore: PropTypes.bool,
  showFilter: PropTypes.bool,
  featured: PropTypes.bool,
  children: PropTypes.node.isRequired,
  limit: PropTypes.number,
  customLinkText: PropTypes.string,
  type: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.string).isRequired,
  relatedToAll: PropTypes.arrayOf(PropTypes.string),
  additionalQueryArgs: PropTypes.shape()
};

TeaserListProvider.defaultProps = {
  showLoadMore: false,
  showFilter: false,
  limit: null,
  featured: false,
  customLinkText: null,
  relatedToAll: undefined,
  additionalQueryArgs: {}
};

export const TeaserListConsumer = TeaserListContext.Consumer;

export default TeaserListProvider;
