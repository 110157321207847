import React, { useContext } from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { DataContext } from '@ccg/core';
import { Teaser, JobTeaser, TeaserHero, CategoryTeaser } from '@components';

import { withTranslation } from '../../../../i18n';
import { formDataHelper } from '../../Embeds/helper/form';

const DynamicTeaser = ({
  t,
  __typename,
  id,
  title,
  colorTheme,
  locations,
  companies,
  slug,
  uri,
  featured,
  client,
  index,
  postDate,
  image,
  teaserImage,
  subline,
  video,
  groupHandle,
  logo,
  tags,
  address,
  phoneNumber,
  email,
  cta,
  personioId,
  formTeaser,
  embeds
}) => {
  const {
    publicRuntimeConfig: { ACTIVE_SITE_HANDLE, GOOGLE_RECAPTCHA_SITEKEY }
  } = getConfig();

  const getPath = type => {
    const types = {
      jobs_jobs_Entry: 'job',
      cases_cases_Entry: 'case',
      news_news_Entry: 'news'
    };

    return types[type];
  };

  const { data } = useContext(DataContext);

  const hasTeaserImage = teaserImage && teaserImage.length > 0;
  const imageForTeaser = hasTeaserImage ? teaserImage : image;

  if (__typename === 'jobs_jobs_Entry') {
    const formEmbed = embeds.filter(e => e.__typename === 'embeds_form_BlockType')[0];

    if (formTeaser && formEmbed && ACTIVE_SITE_HANDLE === 'fischerappelt') {
      return (
        <JobTeaser
          key={id}
          title={title}
          locations={locations}
          companies={companies}
          subline={subline}
          link={{
            href: { pathname: '/job', query: { slug } },
            asPath: `/${uri}`
          }}
          cta={cta || t('teaser.read')}
          isFormTeaser={formTeaser}
          form={formDataHelper(
            formEmbed,
            data.categories,
            personioId,
            data.forms.personioTerms,
            data.forms.personioUploadsDescription,
            GOOGLE_RECAPTCHA_SITEKEY,
            t
          )}
        />
      );
    }

    return (
      <JobTeaser
        key={id}
        title={title}
        locations={locations}
        companies={companies}
        subline={subline}
        link={{
          href: { pathname: '/job', query: { slug } },
          asPath: `/${uri}`
        }}
        cta={cta || t('teaser.read')}
      />
    );
  }

  if (__typename === 'cases_cases_Entry' && featured) {
    return (
      <TeaserHero
        key={id}
        index={index}
        colorTheme={colorTheme}
        accentColor={
          client &&
          client.length > 0 && {
            primary: client[0].ciColorPrimary,
            secondary: client[0].ciColorSecondary
          }
        }
        topic={client && client.length > 0 ? client.map(item => item.title).join(', ') : undefined}
        postDate={
          postDate
            ? new Date(postDate).toLocaleDateString(undefined, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              })
            : undefined
        }
        headline={title}
        subline={subline}
        image={
          imageForTeaser && imageForTeaser.length > 0
            ? {
                ...imageForTeaser[0],
                srcset: {
                  '1800w': imageForTeaser[0].url,
                  '1440w': imageForTeaser[0].width1440,
                  '900w': imageForTeaser[0].width900,
                  '720w': imageForTeaser[0].width720,
                  '390w': imageForTeaser[0].width390
                }
              }
            : undefined
        }
        link={{
          href: { pathname: '/case', query: { slug } },
          asPath: `/${uri}`
        }}
        video={!hasTeaserImage && video && video.length > 0 ? video[0] : undefined}
        tags={tags}
        cta={cta || t('teaser.read')}
      />
    );
  }

  if (
    __typename.includes('solutions') ||
    __typename.includes('industries') ||
    __typename.includes('tasks')
  ) {
    // Check if category is solution and title length is smaller than 2
    const orientation =
      __typename.includes('solutions') && title.split(' ').length <= 2 && subline
        ? 'vertical'
        : 'horizontal';

    return (
      <CategoryTeaser
        key={id}
        headline={title}
        orientation={orientation}
        subline={subline}
        index={index}
        cta={cta || t('teaser.read')}
        image={
          imageForTeaser && imageForTeaser.length > 0
            ? {
                ...imageForTeaser[0],
                srcset: {
                  '1800w': imageForTeaser[0].url,
                  '1440w': imageForTeaser[0].width1440,
                  '900w': imageForTeaser[0].width900,
                  '720w': imageForTeaser[0].width720,
                  '390w': imageForTeaser[0].width390
                }
              }
            : undefined
        }
        link={{
          label: title,
          href: {
            pathname: '/category',
            query: { slug, group: groupHandle }
          },
          asPath: `/${uri}`
        }}
      />
    );
  }

  if (__typename.includes('companies')) {
    return (
      <Teaser
        key={id}
        index={index}
        headline={title}
        subline={subline}
        logo={logo && logo[0] ? logo[0] : undefined}
        image={
          imageForTeaser && imageForTeaser.length > 0
            ? {
                ...imageForTeaser[0],
                srcset: {
                  '1800w': imageForTeaser[0].url,
                  '1440w': imageForTeaser[0].width1440,
                  '900w': imageForTeaser[0].width900,
                  '720w': imageForTeaser[0].width720,
                  '390w': imageForTeaser[0].width390
                }
              }
            : undefined
        }
        link={{
          label: title,
          href: {
            pathname: '/category',
            query: { slug, group: groupHandle }
          },
          asPath: `/${uri}`
        }}
        cta={cta || t('teaser.read')}
      />
    );
  }

  if (__typename.includes('locations')) {
    return (
      <Teaser
        key={id}
        index={index}
        topic={t('teaser.location')}
        headline={title}
        street={`${address.parts.address} ${address.parts.number}`}
        phoneNumber={phoneNumber}
        email={email}
        city={`${address.parts.postcode} ${address.parts.city}`}
        image={
          imageForTeaser && imageForTeaser.length > 0
            ? {
                ...imageForTeaser[0],
                srcset: {
                  '1800w': imageForTeaser[0].url,
                  '1440w': imageForTeaser[0].width1440,
                  '900w': imageForTeaser[0].width900,
                  '720w': imageForTeaser[0].width720,
                  '390w': imageForTeaser[0].width390
                }
              }
            : undefined
        }
        link={{
          label: title,
          href: {
            pathname: '/category',
            query: { slug, group: groupHandle }
          },
          asPath: `/${uri}`
        }}
        cta={cta || t('teaser.read')}
      />
    );
  }

  return (
    <Teaser
      key={id}
      index={index}
      accentColor={
        client &&
        client.length > 0 && {
          primary: client[0].ciColorPrimary,
          secondary: client[0].ciColorSecondary
        }
      }
      colorTheme={colorTheme}
      topic={client && client.length > 0 ? client.map(item => item.title).join(', ') : undefined}
      postDate={
        postDate
          ? new Date(postDate).toLocaleDateString(undefined, {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            })
          : undefined
      }
      headline={title}
      subline={subline}
      featured={featured}
      video={!hasTeaserImage && video && video.length > 0 ? video[0] : undefined}
      image={
        imageForTeaser && imageForTeaser.length > 0
          ? {
              ...imageForTeaser[0],
              srcset: {
                '1800w': imageForTeaser[0].url,
                '1440w': imageForTeaser[0].width1440,
                '900w': imageForTeaser[0].width900,
                '720w': imageForTeaser[0].width720,
                '390w': imageForTeaser[0].width390
              }
            }
          : undefined
      }
      link={
        __typename === 'standard_standard_Entry'
          ? {
              href: { pathname: '/standard', query: { uri } },
              asPath: `/${uri}`
            }
          : {
              href: { pathname: `/${getPath(__typename)}`, query: { slug } },
              asPath: `/${uri}`
            }
      }
      tags={tags}
      cta={cta || t('teaser.read')}
    />
  );
};

DynamicTeaser.propTypes = {
  t: PropTypes.func.isRequired,
  __typename: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  colorTheme: PropTypes.string,
  title: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()),
  companies: PropTypes.arrayOf(PropTypes.shape()),
  logo: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
  slug: PropTypes.string.isRequired,
  uri: PropTypes.string,
  featured: PropTypes.bool,
  client: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.number.isRequired,
  postDate: PropTypes.string,
  image: PropTypes.arrayOf(PropTypes.shape()),
  teaserImage: PropTypes.arrayOf(PropTypes.shape()),
  subline: PropTypes.string,
  video: PropTypes.arrayOf(PropTypes.shape()),
  tags: PropTypes.arrayOf(PropTypes.shape()),
  groupHandle: PropTypes.string,
  address: PropTypes.shape(),
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  cta: PropTypes.string,
  personioId: PropTypes.string,
  formTeaser: PropTypes.bool,
  embeds: PropTypes.arrayOf(PropTypes.shape())
};

DynamicTeaser.defaultProps = {
  colorTheme: null,
  subline: null,
  uri: null,
  locations: null,
  client: null,
  featured: false,
  companies: null,
  logo: undefined,
  postDate: null,
  image: null,
  teaserImage: null,
  video: null,
  tags: null,
  groupHandle: null,
  address: undefined,
  phoneNumber: null,
  email: null,
  cta: null,
  personioId: null,
  formTeaser: null,
  embeds: null
};

export default withTranslation('common')(DynamicTeaser);
