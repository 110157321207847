import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TeaserContainer, TeaserOverview } from '@components';

import { getNextLinkProps } from '../../../../helper';
import { TeaserListContext } from '../context/TeaserListProvider';

import DynamicTeaser from './DynamicTeaser';
import TeaserFilter from './TeaserFilter';
import { withTranslation } from '../../../../i18n';

const TeaserList = ({ t, headline, subline, linkTo }) => {
  const {
    data,
    loading,
    error,
    showFilter,
    loadMoreTeasers,
    showLoadMore,
    limit,
    updateFilter,
    sections,
    customLinkText
  } = useContext(TeaserListContext);

  const onLoadMore =
    data &&
    data.entries.length > 0 &&
    data.entries.length >= limit &&
    data.total !== data.entries.length &&
    showLoadMore
      ? loadMoreTeasers
      : undefined;

  const onReset = () => updateFilter([]);

  if (error) return null;

  return (
    <>
      {((data && data.entries.length > 0) || showFilter) && (headline || subline) && (
        <TeaserOverview headline={headline} subline={subline} link={getNextLinkProps(linkTo)} />
      )}

      {showFilter && <TeaserFilter />}

      <TeaserContainer
        onLoadMore={onLoadMore}
        loading={loading}
        loadMoreLabel={t('teaser.loadMore')}
        noEntriesLabel={sections[0] === 'jobs' ? t('filter.noJobResults') : t('filter.noResults')}
        resetLabel={t('filter.reset')}
        onReset={showFilter && onReset ? onReset : null}
        filtering={showFilter && loading}
        showFilter={showFilter}
        entriesCount={data ? data.entries.length : undefined}
      >
        {data &&
          data.entries.map((item, index) => (
            <DynamicTeaser key={item.id} index={index} cta={customLinkText} {...item} />
          ))}
      </TeaserContainer>
    </>
  );
};

TeaserList.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  linkTo: PropTypes.shape(),
  t: PropTypes.func.isRequired
};

TeaserList.defaultProps = {
  headline: null,
  subline: null,
  linkTo: null
};

export default withTranslation('common')(TeaserList);
