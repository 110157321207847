import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { Filter } from '@components';

import { TeaserListContext } from '../context/TeaserListProvider';
import { withTranslation } from '../../../../i18n';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE }
} = getConfig();

const TeaserFilter = ({ t }) => {
  const { data: { categories } = [], sections, updateFilter, selectedFilter } = useContext(
    TeaserListContext
  );

  const generalFilterCategories =
    ACTIVE_SITE_HANDLE === 'qatar' || ACTIVE_SITE_HANDLE === 'middleeast'
      ? ['events']
      : ['solutions', 'industries'];

  const group =
    sections[0] === 'jobs'
      ? ['companies', 'locations', 'departments', 'seniority']
      : generalFilterCategories;

  const getFilterOptions = useCallback(() => {
    const labels = group.map(item => ({ title: t(`filter.${item}`), id: item }));

    const options =
      categories &&
      categories.length > 1 &&
      categories
        .filter(item => group.includes(item.groupHandle))
        .map(item => ({ id: item.id, title: item.title, group: item.groupHandle }));

    return { labels, options };
  }, [categories, sections]);

  return categories && categories.length > 1 ? (
    <Filter
      selected={selectedFilter}
      onChange={item => {
        updateFilter(item);
      }}
      {...getFilterOptions()}
    />
  ) : null;
};

TeaserFilter.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(TeaserFilter);
